.ss-root {
  width: 100%; }

.ss-input {
  padding-right: 42px; }

.ss-arrow,
.ss-remove {
  position: absolute;
  cursor: pointer;
  color: #ccc;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4; }

.ss-remove {
  right: 26px; }
  .ss-remove::after {
    content: '\D7';
    font-size: 16px;
    font-weight: 500; }

.ss-arrow {
  border-top: 8px solid #ccc;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 0;
  margin: 2px; }

.ss-list {
  display: block;
  max-height: 250px;
  min-width: 30px;
  width: inherit;
  overflow: auto;
  cursor: pointer; }

.ss-over-item {
  background: #eee; }

